import styled from 'styled-components';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';

export const StyledCoreTeamContainer = styled(motion.section)`
  overflow: hidden;
  background: ${({ theme }) => theme.gradients.darkViolet};
  padding: ${({ theme }) => theme.spacing(9, 0, 7)};
`;

export const StyledTitleWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => theme.spacing(0, 'auto', 8)};
  max-width: 600px;

  ${mq.xsLarge`
    max-width: 349px;
    width: 100%;
    height: 100%;
    margin: ${({ theme }) => theme.spacing(-2, 6, 0, 0)};
    text-align: left;
    align-items: flex-start;
    justify-content: center;
  `}
`;

export const StyledDescriptionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0, 4)};
`;

export const StyledBulletWrapper = styled.div`
  /*
   * @NOTE: used display instead of conditional rendering because
   * Swiper lost references to the arrows & pagination and do not trigger any actions like nextSlide().
   * It is workaround of this problem with display property.
   */
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(5, 0)};

  ${mq.xsLarge`
    display: none;
  `}
`;

export const StyledContentWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${mq.xsLarge`
    display: flex;
    align-items: center;
    padding: 0 0 0 calc((100% - ${({ theme }) => theme.sizes.container.xsLarge}) / 2);
  `}
`;

export const StyledLeftArrowWrapper = styled.div`
  /*
   * @NOTE: used display instead of conditional rendering because
   * Swiper lost references to the arrows & pagination and do not trigger any actions like nextSlide().
   * It is workaround of this problem with display property.
   */
  display: none;

  ${mq.xsLarge`
    position: absolute;
    display: block;
    z-index: 1;
    top: ${({ theme }) => theme.spacing(16)};
    left: ${({ theme }) => theme.spacing(2)};
  `}
`;

export const StyledRightArrowWrapper = styled(StyledLeftArrowWrapper)`
  ${mq.xsLarge`
    left: auto;
    right: ${({ theme }) => theme.spacing(2)};
  `}
`;
