import styled, { css } from 'styled-components';

import { SliderBulletProps } from './SliderBullet';

export const StyledWrapper = styled.div<{
  variant: SliderBulletProps['variant'];
}>`
  display: grid;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
  grid-column-gap: ${({ theme }) => theme.spacing(2)};

  ${({ variant }) =>
    variant === 'sm' &&
    css`
      grid-auto-columns: 8px;
    `};

  .swiper-pagination-bullet {
    display: block;
    background: ${({ theme }) => theme.colors.violetGray};
    border: none;
    content: ' ';
    cursor: pointer;
    height: 16px;
    width: 16px;
    overflow: hidden;
    outline: none;
    transition: background 0.3s linear 0s;
    border-radius: 0;
    opacity: 1;

    ${({ variant }) =>
      variant === 'sm' &&
      css`
        height: 8px;
        width: 8px;
      `};

    &-active {
      background: ${({ theme }) => theme.gradients.electricViolet};
    }
  }

  .swiper-pagination-bullet:only-child {
    visibility: hidden;
  }
`;
