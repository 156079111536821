import React, { FC, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import SliderArrow from 'components/Slider/SliderArrow/SliderArrow';
import SliderBullet from 'components/Slider/SliderBullet/SliderBullet';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';

import { useMinWidthQuery } from 'hooks/useMedia';
import { revealLeft, fadeIn } from 'theme/animations';
import fromCMS from 'utils/fromCMS';
import Person from 'utils/types/Person';
import Button from 'utils/types/Button';

import { PersonTile } from './PersonTile/PersonTile';
import {
  StyledCoreTeamContainer,
  StyledDescriptionWrapper,
  StyledTitleWrapper,
  StyledBulletWrapper,
  StyledContentWrapper,
  StyledLeftArrowWrapper,
  StyledRightArrowWrapper
} from './CoreTeam.styles';

export type CoreTeamProps = {
  header: string;
  description: MDXRemoteSerializeResult;
  personTiles: Array<Person>;
  button: Button;
};

export const CoreTeam: FC<CoreTeamProps> = ({ header, description, button, personTiles }) => {
  const isDesktop = useMinWidthQuery('xsLarge');
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const bulletsRef = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0
  });

  return (
    <StyledCoreTeamContainer ref={ref} animate={inView ? 'visible' : 'hidden'} variants={revealLeft}>
      <StyledContentWrapper>
        <StyledTitleWrapper variants={fadeIn}>
          <Typography variant="h3" color="secondary">
            {header}
          </Typography>
          <StyledDescriptionWrapper>
            <Typography variant="body1" color="secondary">
              <Marked>{description}</Marked>
            </Typography>
          </StyledDescriptionWrapper>
          <LinkedButton href={button.href}>{button.buttonCopy}</LinkedButton>
        </StyledTitleWrapper>
        <Swiper
          slidesPerView="auto"
          threshold={!isDesktop ? 0 : 10}
          centeredSlides={!isDesktop}
          spaceBetween={isDesktop ? 72 : 30}
          slidesOffsetAfter={isDesktop ? 72 : 30}
          loop={!isDesktop}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current
          }}
          pagination={{
            el: bulletsRef.current,
            clickable: true
          }}
        >
          {personTiles.map(({ id, name, surname, position, violetPhoto }) => (
            <SwiperSlide key={id} style={{ width: 320 }}>
              <PersonTile
                name={`${name} ${surname}`}
                position={position}
                src={violetPhoto?.file.url && fromCMS(violetPhoto.file.url)}
                alt={violetPhoto?.alt}
              />
            </SwiperSlide>
          ))}
          <>
            <StyledBulletWrapper>
              <SliderBullet ref={bulletsRef} />
            </StyledBulletWrapper>
            <>
              <StyledLeftArrowWrapper>
                <SliderArrow ref={prevRef} direction="left" position="static" />
              </StyledLeftArrowWrapper>
              <StyledRightArrowWrapper>
                <SliderArrow ref={nextRef} direction="right" position="static" />
              </StyledRightArrowWrapper>
            </>
          </>
        </Swiper>
      </StyledContentWrapper>
    </StyledCoreTeamContainer>
  );
};
